@import url('https://fonts.googleapis.com/css?family=Eczar:400,700');
body {
  margin: 0;
  font-family: Eczar, Georgia, 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1em;
  line-height: 1.6em;
}

p {
  line-height: 1.4em;
}

.landing__key_art {
  margin: 0;
  text-align: center;
  background-image: url(./images/bg/bg-a-fire.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  overflow: hidden;
}
.landing__key_art--inner {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  @media (max-width: $mobile-breakpoint) {
    margin-top: 40px;
  }
}

.landing__image {
  width: 100%;
  max-height: 100%;
  @media (max-width: $mobile-breakpoint) {
    width: 80%;
  }
}
